body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: azure; */
  /* background-image: repeating-linear-gradient(white, azure); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.header {
  
  min-height: 200px;
  min-width: 800px;
  border-radius: 20px;
  background-image: linear-gradient(to top, black, rgb(54, 54, 54));
  margin: 40px;
  padding: 10px;
  background: linear-gradient(70deg, var(--c1, rgb(36, 238, 245)), var(--c2, rgb(198, 216, 255)) 0%, rgb(42, 238, 245)) var(--x, 0)/ 200%;
  transition: 2s;
}

.header:hover {
  --x:100%
}


.header-title {
  color:azure;
  text-transform: uppercase;
  border-color: azure;
  border-style:solid;
  border-width: thin;
  border-top: 0pt;
  border-left: 0pt;
  border-right: 0pt;
  filter: drop-shadow(4px 4px 2px black);
}



.subjects {
  /* display:contents; */
  
  max-height: 300px;
  display:flex;

}

.subject {
  flex: 1; 
  width: 300px;
  min-width: 33%;
  max-width: 33%;
  /* padding-left: 10px; */
  /* padding-right:10px; */
  margin-left: 10px;
  margin-right: 10px;
}


#subject2 {
  border-color: azure;
  border-style:solid;
  border-width: thin;
  border-top: 0pt;
  border-bottom: 0pt;
  overflow-y: scroll;
}


.project {
  display:flex;
  color:black;
  min-height: 40px;
  border-radius: 20px;
  max-width: 100%;
  margin:20px;
  cursor: pointer;
  background-image: linear-gradient(to bottom, white, azure);
  /* background-image: linear-gradient(to bottom, gray, black); */
  
  filter: brightness(.5);
  
  justify-content: left;
  align-items: center;

  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  
}


.project:hover {

  background-image: linear-gradient(to bottom, white, azure);

  /* -webkit-filter: brightness(130%); */
  filter: brightness(100%) drop-shadow(0px 0px 5px white);
  /* color:gray; */
}


details[open] summary ~ * {
  animation: sweep 1s ease-in-out;
}

@keyframes sweep {
  0%    {opacity: 0; transform: translateX(-10px)}
  100%  {opacity: 1; transform: translateX(0)}
}

.project-icon {
  height: 50px;
  width: 50px;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.project-name {
  margin-right: 10px;
}

.subject-title {
  filter: drop-shadow(2px 2px 1px black);
  
  color:azure;
  text-decoration: underline;
}

.contact-info {
  vertical-align: bottom;
}


.paragraph {
  /* color:azure; */
  /* color: rgb(0, 0, 0);
   */
   color: azure;
   filter: drop-shadow(2px 2px 1px black);

  font-weight: bold;
  /* font-size: 19px; */
  /* stroke-width: 15px; */
  /* -webkit-text-stroke-color: rgb(255, 237, 214); */
  /* stroke:bisque; */
  /* stroke-width: 2px; */
  /* font-size: 17px; */

}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px azure;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: azure;
  border-radius: 3px;
}

.projects-header {
  /* min-height: 200px; */
  border-radius: 20px;
  background-image: linear-gradient(to top, black, rgb(54, 54, 54));
  margin: 40px;
  padding-left: 10px;
  padding-right:10px;

  background: linear-gradient(70deg, var(--c1, rgb(36, 238, 245)), var(--c2, rgb(255, 240, 198)) 0%, rgb(42, 238, 245)) var(--x, 0)/ 200%;
  transition: 2s;
}

.projects-header:hover {
  --x:100%
}


.project-item {
  text-align: left;
  /* display:contents; */
}
.project-summary {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color:gray;
  cursor: pointer;
}

img {
  max-width: 100%;
  /* max-height: 100%; */
  /* display: block; */
}